import {Box, Button, ButtonGroup, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Navbar from "components/shared/navbar/navBar";
import {useNavigate, useParams} from "react-router-dom";
import {BarClient, PostClient} from "../../services/api.service";
import WidgetWrapper from "../../components/shared/WidgetWrapper";
import FlexBetween from "../../components/shared/FlexBetween";
import styles from "./postPage.module.css";

const PostPage = () => {
    const dispatch = useDispatch();
    const [bar, setBar] = useState(null);
    const [businessLevel, setBusinessLevel] = useState(1);
    const [currentPrice, setCurrentPrice] = useState(0);
    const {palette} = useTheme();
    const user = useSelector((state) => state.user);
    const token = useSelector((state) => state.token);
    const {barId} = useParams();
    const navigate = useNavigate();

    const postClient = new PostClient();
    const barClient = new BarClient();

    async function getBar(): void {
        const bar = await barClient.getBar(barId, token);
        console.log(bar);
        setBar(bar);
        setCurrentPrice(bar.coverPrice);
    }

    const handlePost = async () => {
        try {
            await postClient.createPost(user._id, barId, currentPrice, businessLevel, token);
            navigate('/profile/' + barId)
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getBar();
    }, []);

    return (
        <Box>
            <Navbar></Navbar>
            <Box className={styles.buttonGroups} color={palette.neutral.main}>
                <h1 className={styles.barName}>{bar?.name}</h1>
                <WidgetWrapper>
                    <h2>Cover?</h2>
                    <ButtonGroup className={styles.buttonGroup} variant="contained"
                                 aria-label="outlined primary button group" sx={{height: "3rem"}}>
                        <Button color={currentPrice === 0 ? "primary" : "secondary"}
                                onClick={() => setCurrentPrice(0)}>Free</Button>
                        <Button color={currentPrice === 5 ? "primary" : "secondary"}
                                onClick={() => setCurrentPrice(5)}>$5 </Button>
                        <Button color={currentPrice === 10 ? "primary" : "secondary"}
                                onClick={() => setCurrentPrice(10)}>$10 </Button>
                        <Button color={currentPrice === 15 ? "primary" : "secondary"}
                                onClick={() => setCurrentPrice(15)}>$15 </Button>
                        <Button color={currentPrice === 20 ? "primary" : "secondary"}
                                onClick={() => setCurrentPrice(20)}>$20+</Button>
                    </ButtonGroup>
                    {/*<h2>Crowd?</h2>*/}
                    {/*<ButtonGroup className={styles.buttonGroup} variant="contained" aria-label="outlined primary button group">*/}
                    {/*    <Button color={businessLevel < 1 ? "secondary" : "primary"}*/}
                    {/*            onClick={() => setBusinessLevel(1)}><EmojiPeopleIcon fontSize="large"></EmojiPeopleIcon></Button>*/}
                    {/*    <Button color={businessLevel < 2 ? "secondary" : "primary"}*/}
                    {/*            onClick={() => setBusinessLevel(2)}><EmojiPeopleIcon fontSize="large"></EmojiPeopleIcon></Button>*/}
                    {/*    <Button color={businessLevel < 3 ? "secondary" : "primary"}*/}
                    {/*            onClick={() => setBusinessLevel(3)}><EmojiPeopleIcon fontSize="large"></EmojiPeopleIcon></Button>*/}
                    {/*    <Button color={businessLevel < 4 ? "secondary" : "primary"}*/}
                    {/*            onClick={() => setBusinessLevel(4)}><EmojiPeopleIcon fontSize="large"></EmojiPeopleIcon></Button>*/}
                    {/*    <Button color={businessLevel < 5 ? "secondary" : "primary"}*/}
                    {/*            onClick={() => setBusinessLevel(5)}><EmojiPeopleIcon fontSize="large"></EmojiPeopleIcon></Button>*/}
                    {/*</ButtonGroup>*/}
                    <FlexBetween>
                        <Button
                            onClick={handlePost}
                            sx={{
                                color: palette.neutral.main,
                                backgroundColor: palette.primary.main,
                                borderRadius: "3rem",
                                width: "100%",
                                height: "3rem"
                            }}
                        >
                            POST
                        </Button>
                    </FlexBetween>
                </WidgetWrapper>
            </Box>
        </Box>
    );
};

export default PostPage;
