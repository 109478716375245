import {Box, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import Navbar from "components/shared/navbar/navBar";
import {BarClient} from "../../services/api.service";
import {LineWave} from 'react-loader-spinner'
import BarPreview from "../../components/barPreview/barPreview";

const HomePage = () => {
    // localStorage.setItem("message", "saved in browser storage");
    // localStorage.getItem("message");
    const [bars: [], setBars] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const token = useSelector((state) => state.token);
    const {palette} = useTheme();

    const barClient = new BarClient();

    async function getBars() {
        setBars(await barClient.getBars(token));
        setIsLoading(false);
    }

    useEffect(() => {
        getBars();
    }, []);

    return (isLoading ? (<Box>
                <Navbar></Navbar>
                <LineWave
                    height="300"
                    width="300"
                    loading={isLoading}
                    color={palette.primary.main}
                    ariaLabel="line-wave"
                    wrapperStyle={{}}
                    wrapperClass="loadingSpinner"
                    visible={true}
                    firstLineColor=""
                    middleLineColor=""
                    lastLineColor=""
                />
            </Box>)
            :
            <Box>
                <Navbar></Navbar>
                <Box>
                    {bars?.map(bar =>
                        <BarPreview bar={bar} key={bar?._id}></BarPreview>
                    )}
                </Box>
            </Box>

    );
}
export default HomePage;
