import {useState} from "react";
import {Box, Button, TextField, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLogin} from "state";
import {AuthClient} from "../../services/api.service";

const registerSchema = yup.object().shape({
    userName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
});

const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
});

const initialValuesRegister = {
    userName: "",
    email: "",
    password: "",
};

const initialValuesLogin = {
    email: "",
    password: "",
};

const Form = () => {
    const [pageType, setPageType] = useState("login");
    const {palette} = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";

    const authClient = new AuthClient();

    const register = async (values, onSubmitProps) => {
        // this allows us to send form info with image
        console.log("CLICKED");
        const savedUser = await authClient.register(values);
        onSubmitProps.resetForm();

        if (savedUser) {
            setPageType("login");
        }
    };

    const login = async (values, onSubmitProps) => {
        const loggedIn = await authClient.login(values);
        onSubmitProps.resetForm();
        if (loggedIn) {
            dispatch(
                setLogin({
                    user: loggedIn.user,
                    token: loggedIn.token,
                })
            );
            navigate("/home");
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await login(values, onSubmitProps);
        if (isRegister) await register(values, onSubmitProps);
    };

    return (
        <Formik
            onSubmit={handleFormSubmit}
            initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
            validationSchema={isLogin ? loginSchema : registerSchema}
        >
            {({
                  values,
                  errors,
                  touched,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  resetForm,
              }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="30px"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        sx={{
                            "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                        }}
                    >
                        {isRegister && (
                            <>
                                <TextField
                                    label={<span style={{ color: 'white' }}>Username</span>}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.userName || ''}
                                    name="userName"
                                    error={Boolean(touched.userName) && Boolean(errors.userName)}
                                    helperText={touched.userName && errors.userName}
                                    InputProps={{ inputProps: { style: { color: '#fff' }}}}
                                    sx={{gridColumn: "span 4"}}
                                />
                                <TextField
                                    label={<span style={{ color: 'white' }}>Invite Code</span>}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.inviteCode || ''}
                                    name="inviteCode"
                                    error={Boolean(touched.inviteCode) && Boolean(errors.inviteCode)}
                                    helperText={touched.inviteCode && errors.inviteCode}
                                    InputProps={{ inputProps: { style: { color: '#fff' }}}}
                                    sx={{gridColumn: "span 4"}}
                                />
                            </>
                        )}

                        <TextField
                            label={<span style={{ color: 'white' }}>Email</span>}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.email || ''}
                            name="email"
                            error={Boolean(touched.email) && Boolean(errors.email)}
                            helperText={touched.email && errors.email}
                            InputProps={{ inputProps: { style: { color: '#fff' }}}}
                            sx={{gridColumn: "span 4"}}
                        />
                        <TextField
                            label={<span style={{ color: 'white' }}>Password</span>}
                            type="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            value={values.password || ''}
                            name="password"
                            error={Boolean(touched.password) && Boolean(errors.password)}
                            helperText={touched.password && errors.password}
                            InputProps={{ inputProps: { style: { color: '#fff' }}}}
                            sx={{gridColumn: "span 4"}}
                        />
                    </Box>

                    {/* BUTTONS */}
                    <Box>
                        <Button
                            fullWidth
                            type="submit"
                            sx={{
                                m: "2rem 0",
                                p: "1rem",
                                backgroundColor: palette.primary.main,
                                color: palette.background.alt,
                                "&:hover": {color: palette.primary.main},
                            }}
                        >
                            {isLogin ? "LOGIN" : "REGISTER"}
                        </Button>
                        <Typography
                            onClick={() => {
                                setPageType(isLogin ? "register" : "login");
                                resetForm();
                            }}
                            sx={{
                                textDecoration: "underline",
                                color: palette.primary.main,
                                "&:hover": {
                                    cursor: "pointer",
                                    color: palette.primary.light,
                                },
                            }}
                        >
                            {isLogin
                                ? "Don't have an account? Sign Up here."
                                : "Already have an account? Login here."}
                        </Typography>
                    </Box>
                </form>
            )}
        </Formik>
    );
};

export default Form;
