// color design tokens export
export const colorTokens = {
    grey: {
        0: "#FFFFFF",
        10: "#F6F6F6",
        50: "#F0F0F0",
        100: "#E0E0E0",
        200: "#C2C2C2",
        300: "#A3A3A3",
        400: "#858585",
        500: "#666666",
        600: "#4D4D4D",
        700: "#333333",
        800: "#1A1A1A",
        900: "#0A0A0A",
        1000: "#000000",
    },
    primary: {
        50: "#FFF7E6",
        100: "#FFEBCC",
        200: "#FFE099",
        300: "#FFC666",
        400: "#FFAB33",
        500: "#FF9000",
        600: "#CC7A00",
        700: "#995200",
        800: "#D5B3FD",
        900: "#7b00ff",
    },
};

// mui theme settings
export const themeSettings = (mode) => {
    return {
        palette: {
            primary: {
                dark: colorTokens.primary[900],
                main: colorTokens.primary[900],
                light: colorTokens.primary[900],
            },
            secondary: {
                dark: colorTokens.grey[1000],
                main: colorTokens.grey[1000],
                light: colorTokens.grey[1000],
            },
            neutral: {
                dark: colorTokens.grey[0],
                main: colorTokens.grey[0],
                mediumMain: colorTokens.grey[0],
                medium: colorTokens.grey[0],
                light: colorTokens.grey[0],
            },
            background: {
                default: colorTokens.grey[900],
                alt: colorTokens.grey[800],
            },
        },
        typography: {
            fontFamily: ["Rubik"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 40,
            },
            h2: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 32,
            },
            h3: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 24,
            },
            h4: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 20,
            },
            h5: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 16,
            },
            h6: {
                fontFamily: ["Rubik"].join(","),
                fontSize: 14,
            },
        },
    };
};
