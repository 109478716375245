// Stephen Bowen 2023

class APIClient {
    baseUrl = 'https://api.doorguyapp.com/';

    async authenticatedCall(method: string, endpoint: string, token: string, body = null): any {
        const url = this.baseUrl + endpoint;
        if (body === null) {
            const response = await fetch(url, {
                method: method,
                headers: {Authorization: `Bearer ${token}`},
            });
            return response.json();
        } else {
            const response = await fetch(url, {
                method: method,
                headers: {Authorization: `Bearer ${token}`, 'content-type': 'application/json'},
                body: body
            });
            return response.json();
        }

    }

    async call(method: string, endpoint: string, body = null): any {
        const url = this.baseUrl + endpoint;
        if (body === null) {
            const response = await fetch(url, {
                method: method
            });
            return response.json();
        } else {
            const response = await fetch(url, {
                method: method,
                headers: {'content-type': 'application/json'},
                body: body
            });
            return await response.json();
        }
    }
}

const BarClient = class BarClient extends APIClient {
    async getBar(barId: string, token: string): any {
        const method = 'GET';
        const request = 'bars/' + barId;
        return this.authenticatedCall(method, request, token);
    }

    async getBars(token: string): any {
        const method = 'GET';
        const request = 'bars';
        return this.authenticatedCall(method, request, token);
    }
}

const PostClient = class PostClient extends APIClient {
    async getBarPosts(barId: string, token: string): any {
        const method = 'GET';
        const request = 'posts/' + barId;
        return this.authenticatedCall(method, request, token);
    }

    async createPost(userId: string, barId: string, coverPrice: string, businessLevel: string, token: string): any {
        const method = 'POST';
        const request = 'posts/create-post';
        const body = JSON.stringify({
            userId: userId,
            barId: barId,
            coverPrice: coverPrice,
            businessLevel: businessLevel
        });
        return this.authenticatedCall(method, request, token, body);
    }
}

const UserClient = class UserClient extends APIClient {
    async getUser(userId: string, token: string): any {
        const method = "GET";
        const request = 'users/' + userId;
        return this.authenticatedCall(method, request, token);
    }
}

const AuthClient = class AuthClient extends APIClient {
    async login(values) {
        const method = 'POST';
        const request = 'auth/login'
        const body = JSON.stringify(values);
        return this.call(method, request, body);
    }

    async register(values) {
        const method = 'POST';
        const request = 'auth/register';
        const body = JSON.stringify(values);
        return this.call(method, request, body);

    }
}

export {BarClient, PostClient, UserClient, AuthClient}
