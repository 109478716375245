import {MenuItem, Typography, useMediaQuery, useTheme,} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {setLogout} from "state";
import {useNavigate} from "react-router-dom";
import FlexBetween from "components/shared/FlexBetween";
import LogoutIcon from '@mui/icons-material/Logout';

const Navbar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

    const theme = useTheme();
    const neutralLight = theme.palette.neutral.light;
    const dark = theme.palette.neutral.dark;
    const background = theme.palette.background.default;
    const primary = theme.palette.primary.main;
    const alt = theme.palette.background.alt;

    const fullName = `${user.firstName} ${user.lastName}`;

    return (
        <FlexBetween padding="1rem 6%" backgroundColor={alt}>
            <FlexBetween gap="1.75rem">
                <Typography
                    fontWeight="bold"
                    fontSize="clamp(1rem, 2rem, 2.25rem)"
                    color="primary"
                    onClick={() => navigate("/home")}
                    sx={{
                        "&:hover": {
                            color: primary,
                            cursor: "pointer",
                        },
                    }}
                >
                    DoorGuy
                </Typography>
            </FlexBetween>
            <MenuItem onClick={() => dispatch(setLogout())}><LogoutIcon fontSize="large"
                                                                        color="primary"></LogoutIcon></MenuItem>
        </FlexBetween>
    );
};

export default Navbar;
