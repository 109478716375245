import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import HomePage from "scenes/homePage/homePage";
import LoginPage from "scenes/loginPage/loginPage";
import BarPage from "scenes/barPage/barPage";
import {useMemo} from "react";
import {useSelector} from "react-redux";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {createTheme} from "@mui/material/styles";
import {themeSettings} from "./theme";
import PostPage from "scenes/postPage/postPage";

function App() {
    const mode = useSelector((state) => state.mode);
    const theme = useMemo(() => createTheme(themeSettings("dark")), [mode]);
    const isAuth = Boolean(useSelector((state) => state.token));

    return (
        <div className="app">
            <BrowserRouter>
                <ThemeProvider theme={theme}>
                    <CssBaseline/>
                    <Routes>
                        <Route path="/" element={<LoginPage/>}/>
                        <Route
                            path="/home"
                            element={isAuth ? <HomePage/> : <Navigate to="/"/>}
                        />
                        <Route
                            path="/profile/:barId"
                            element={isAuth ? <BarPage/> : <Navigate to="/"/>}
                        />
                        <Route
                            path="/post/:barId"
                            element={isAuth ? <PostPage/> : <Navigate to="/"/>}
                        />
                        <Route
                            path="/*"
                            element={<Navigate to="/home" replace={true}/>}
                        />
                    </Routes>
                </ThemeProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
