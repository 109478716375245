import { Box, Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import Form from "./Form";
// import PrivacyPolicy from "./privacy-policy.pdf";

const LandingPage = () => {
    const { palette } = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    return (
        <Box color={palette.neutral.main}>
            <Box
                width="100%"
                backgroundColor={palette.background.alt}
                p="1rem 6%"
                textAlign="center"
            >
                <Typography fontWeight="bold" fontSize="32px" color="primary">
                    DoorGuy
                </Typography>
            </Box>

            <Box
                width={isNonMobileScreens ? "50%" : "93%"}
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={palette.background.alt}
            >
                <Typography fontWeight="500" variant="h1" sx={{ mb: "1.5rem", textAlign: "center", justifyContent: "center" }}>
                    DoorGuy is here to make your life easier.
                </Typography>
                <Typography variant="h3" sx={{ mb: "1.5rem", textAlign: "center" }}>
                    Download the app today. The perfect bar crawl is just a tap away.
                </Typography>
                <Typography variant="body1" sx={{ mt: "1.5rem", textAlign: "center" }}>
                    Contact us for any questions or feedback.
                </Typography>
                <Typography variant="body1" sx={{ mt: "1.5rem", textAlign: "center" }}>
                    <a href={"mailto:thedoorguys@doorguyapp.com"}>thedoorguys@doorguyapp.com</a>
                </Typography>
            </Box>
            <Box
                width={isNonMobileScreens ? "50%" : "93%"}
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={palette.background.alt}
            >
                <Typography variant="h4" sx={{ mb: "1.5rem", textAlign: "center" }}>
                    Privacy Policy
                </Typography>
                <Typography variant="body1" sx={{ mt: "1.5rem", textAlign: "center" }}>
                    Thank you for choosing to use our app. Protecting your privacy and personal information is a top priority for us. This privacy policy outlines how we collect, use, and protect your information when you use our app.

                    Information We Collect

                    When you use our app, we may collect certain information from you, including your location. However, we do not store this information on our servers or share it with third parties. We only use your location to show you relevant information about bars in your area. We do not collect or store any other personally identifiable information from you.

                    Use of Information

                    We use the information we collect from you to provide you with a better experience when using our app. Specifically, we use your location to show you relevant information about bars in your area. We do not use this information for any other purpose or share it with third parties.

                    Security of Information

                    We take the security of your information seriously and take appropriate measures to protect it. We do not store your location information on our servers or share it with third parties. We also use industry-standard encryption to protect any information that is transmitted between our app and our servers.

                    Changes to this Privacy Policy

                    We reserve the right to update or modify this privacy policy at any time. If we make any material changes to this policy, we will notify you by posting the new policy on our website or within the app itself.

                    Contact Us

                    If you have any questions or concerns about our privacy policy or how we handle your information, please contact us at thedoorguys@doorguyapp.com
                </Typography>
            </Box>
            <Box
                width={isNonMobileScreens ? "50%" : "93%"}
                p="2rem"
                m="2rem auto"
                borderRadius="1.5rem"
                backgroundColor={palette.background.alt}
            >
                <Typography variant="h4" sx={{ mb: "1.5rem", textAlign: "center" }}>
                    See DoorGuy in action
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                    <img src="/assets/homeScreen.png" alt="Screenshot 1" style={{width: "50%", padding: "5%"}} />
                    <img src="/assets/barScreen.png" alt="Screenshot 2" style={{width: "50%", padding: "5%"}} />
                    <img src="/assets/postScreen.png" alt="Screenshot 3" style={{width: "50%", padding: "5%"}} />
                </Box>
            </Box>
            <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={palette.background.alt}
      >
        <Typography
          variant="h2"
          sx={{ mb: "1.5rem", textAlign: "center" }}
        >
          Frequently Asked Questions
        </Typography>
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ mt: "1rem", mb: "0.5rem" }}
        >
          Q: How do I download the DoorGuy app?
        </Typography>
        <Typography variant="body1">
          A: You can download the DoorGuy app from the iOS App Store.
        </Typography>
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ mt: "1rem", mb: "0.5rem" }}
        >
          Q: How do I report a problem or provide feedback?
        </Typography>
        <Typography variant="body1">
          A: You can contact us at thedoorguys@doorguyapp.com for any questions, problems, or feedback.
        </Typography>
        <Typography
          variant="h5"
          fontWeight="500"
          sx={{ mt: "1rem", mb: "0.5rem" }}
        >
          Q: Is my personal information secure?
        </Typography>
        <Typography variant="body1">
          A: Yes, we take the security of your personal information seriously and use industry-standard encryption to protect it. Please refer to our Privacy Policy for more details.
        </Typography>
            <Typography
            variant="h5"
            fontWeight="500"
            sx={{ mt: "1rem", mb: "0.5rem" }}
            >
            Q: How do I find bars near me using the DoorGuy app?
            </Typography>
            <Typography variant="body1">
            A: Simply open the app, and it will automatically display a list of bars in Columbia Missouri. 
            </Typography>
            </Box>
        </Box>
    );
};

export default LandingPage;
