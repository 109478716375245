import {Box, useMediaQuery, useTheme} from "@mui/material";
import {useNavigate} from "react-router-dom";
import styles from "./barPreview.module.css";

const BarPreview = ({...props}) => {
    const navigate = useNavigate();
    const {palette} = useTheme();
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    return (
        <Box
            className={styles.barPreview}
            onClick={() => navigate('/profile/' + props.bar._id)}
            marginTop={2}
            marginLeft={2}
            marginRight={2}
            color={palette.neutral.main}
        >
            <div className={styles.barName}>
                <h1>{props.bar.name}</h1>
                <h3>
                    Closes at:{" "}
                    {new Date(props.bar?.closeTime).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    })}
                </h3>
            </div>
            <div className={styles.barPrice}>
                <h1>${props.bar.coverPrice}</h1>
                <h3>As of {new Date(props.bar?.updatedAt).toLocaleString("en-US", {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                })}</h3>
            </div>
        </Box>
    );
}

export default BarPreview;
