import {Box, Button, useMediaQuery, useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import Navbar from "components/shared/navbar/navBar";
import BarPost from "../../components/barPost/barPost";
import styles from "./barPage.module.css";

import {BarClient, PostClient} from "../../services/api.service";
import {LineWave} from "react-loader-spinner";

const BarPage = () => {

    const [bar, setBar] = useState(null);
    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const {barId} = useParams();

    const {palette} = useTheme();
    const token = useSelector((state) => state.token);
    const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
    const navigate = useNavigate();

    const barClient = new BarClient();
    const postClient = new PostClient();

    async function getBar(): void {
        const bar = await barClient.getBar(barId, token);
        setBar(bar);
    }

    async function getPosts(): void {
        const posts = await postClient.getBarPosts(barId, token);
        setPosts(posts);
        setIsLoading(false);
    }

    useEffect(() => {
        getBar();
        getPosts();
    }, []);

    return (isLoading ? (
            <Box>
                <Navbar></Navbar>
                <LineWave
                    height="300"
                    width="300"
                    loading={isLoading}
                    color={palette.primary.main}
                    ariaLabel="line-wave"
                    wrapperStyle={{}}
                    wrapperClass="loadingSpinner"
                    visible={true}
                    firstLineColor=""
                    middleLineColor=""
                    lastLineColor=""
                />
            </Box>)
        : (<Box color={palette.neutral.main}>
            <Navbar></Navbar>
            <h1 className={styles.barName}>{bar?.name}</h1>
            <h3 className={styles.hours}>Closes at: {new Date(bar?.closeTime).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })}</h3>
            <h2 className={styles.barPrice}>${bar?.coverPrice}</h2>
            <h3 className={styles.lastCheckin}>Cover as of {new Date(bar?.updatedAt).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true
            })}</h3>
            <Box
                width="100%"
                display={isNonMobileScreens ? "flex" : "block"}
                gap="2rem"
                justifyContent="center"
            >
                <Box
                    flexBasis={isNonMobileScreens ? "42%" : undefined}
                    className={styles.checkInButton}
                >
                    <Button
                        onClick={() => navigate("/post/" + barId)}

                        sx={{
                            color: palette.neutral.main,
                            backgroundColor: palette.primary.main,
                            borderRadius: "3rem",
                            width: "100%",
                            height: "3rem"
                        }}
                    >
                        Check in
                    </Button>
                    <Box m="2rem 0"/>
                </Box>
            </Box>
            <Box>
                {posts?.map(post => (
                    <div key={post._id} className={styles.barPost}>
                        <BarPost coverPrice={post.coverPrice} postTime={post.createdAt} postUser={post.userName}/>
                    </div>
                ))}
            </Box>
        </Box>));
};

export default BarPage;
